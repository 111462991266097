type EnumValue<T extends string> = Readonly<T> & {
  __literalType: T;
  charCodeAt: T['charCodeAt'] & {
    // obfuscate type metadata
    __typeSafe: 'EnumValue';
  };
};

/**
 * Since [TS enums are bad](https://www.google.com/search?q=ts+enums+are+bad),
 * this utility provides a way to use plain JS objects as enums.
 * @param keys the keys/values of the enum
 * @returns a frozen plain object to be used as the enum
 *
 * @example
 * ```ts
 * // first you create the enum object using makeEnum()
 * export const EngineTypes = makeEnum(
 *   'internalCombustion',
 *   'electric',
 *   'jet',
 * );
 *
 * // then you export a type with the same name as the object
 * export type EngineTypes = MakeEnumType<typeof EngineTypes>;
 *
 * // When you import the enum, TypeScript can figure out automatically
 * // when it's used as a value or as a type
 * import { EngineTypes } from '...';
 *
 * export const makeCar = (engine: EngineTypes, color: string) => {
 * //                                  ^ used as a type here
 *   switch (engine) {
 *     case EngineTypes.electric:
 *       //       ^ used as a value here
 *   }
 * }
 * ```
 */
export const makeEnum = <T extends string[]>(...keys: T) => {
  const enumObject = {} as {
    [K in T[number]]: EnumValue<K>;
  };

  for (const key of keys) {
    enumObject[key] = key;
  }

  return Object.freeze(enumObject);
};

export type MakeEnumType<T> = T[keyof T] extends EnumValue<any>
  ? T[keyof T]
  : never;

export const enumToLiteral = <T extends EnumValue<any>>(t: T): T['__literalType'] => {
  return t as any;
};

export type EnumToLiteral<T extends EnumValue<any>> = ReturnType<typeof enumToLiteral<T>>;
