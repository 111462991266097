import { css } from 'styled-components';

import { desktopOnly } from 'style/mixins';

export const SIDEBAR_MOBILE_PANELS_EMBED_LAYOUT = css`
  position: absolute !important;
  left: 0px !important;
  right: 0px !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
`;

export const SIDEBAR_DESKTOP_MAX_WIDTH_LAYOUT = css`
  ${desktopOnly`
      & > * {
        width: 100%;
      }
  `}
`;
