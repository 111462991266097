/**
 * returns an object from which you can read/call any property
 * as deeply nested as you want and never get an error. **Useful
 * for initializing react contexts**;
 *
 * For example:
 * ```
 * const obj = unbreakable();
 * // no `undefined` error
 * obj.foo().bar.whatever().tryMe.i.told.you().you.cant.break().me
 * ```
 */
export const unbreakable = (): any => new Proxy(
  unbreakable,
  { get: unbreakable },
);
