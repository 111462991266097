import { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  ADMIN_FONT_FAMILY,
  BODY_FONT_FAMILY,
  TITLE_FONT_FAMILY,
} from 'style/constants';

const adminText = (textStyle: FlattenSimpleInterpolation) => css`
  ${textStyle}
  font-family: ${ADMIN_FONT_FAMILY};
`;

const siteText = (textStyle: FlattenSimpleInterpolation) => css`
  ${textStyle}
  font-family: ${BODY_FONT_FAMILY}, sans-serif;
`;

const siteHeadingText = (textStyle: FlattenSimpleInterpolation) => css`
  ${textStyle}
  font-family: ${TITLE_FONT_FAMILY}, sans-serif;
`;

/**
 * Body text styles
 */

const BASE_TEXT_BODY_XS = css`
  font-size: 0.75rem;
  line-height: 1rem;
`;

const BASE_TEXT_BODY_S = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const BASE_TEXT_BODY_M = css`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const BASE_TEXT_BODY_L = css`
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

const BASE_TEXT_BODY_XL = css`
  font-size: 1.25rem;
  line-height: 2rem;
`;

const BASE_TEXT_BODY_XXL = css`
  font-size: 1.5rem;
  line-height: 2.375rem;
`;

const BASE_TEXT_BODY_XS_REGULAR = css`
  ${BASE_TEXT_BODY_XS}
  font-weight: 400;
`;

const BASE_TEXT_BODY_S_REGULAR = css`
  ${BASE_TEXT_BODY_S}
  font-weight: 400;
`;

const BASE_TEXT_BODY_M_REGULAR = css`
  ${BASE_TEXT_BODY_M}
  font-weight: 400;
`;

const BASE_TEXT_BODY_L_REGULAR = css`
  ${BASE_TEXT_BODY_L}
  font-weight: 400;
`;

const BASE_TEXT_BODY_XL_REGULAR = css`
  ${BASE_TEXT_BODY_XL}
  font-weight: 400;
`;

const BASE_TEXT_BODY_XXL_REGULAR = css`
  ${BASE_TEXT_BODY_XXL}
  font-weight: 400;
`;

const BASE_TEXT_BODY_XS_MEDIUM = css`
  ${BASE_TEXT_BODY_XS}
  font-weight: 500;
`;

const BASE_TEXT_BODY_S_MEDIUM = css`
  ${BASE_TEXT_BODY_S}
  font-weight: 500;
`;

const BASE_TEXT_BODY_M_MEDIUM = css`
  ${BASE_TEXT_BODY_M}
  font-weight: 500;
`;

const BASE_TEXT_BODY_L_MEDIUM = css`
  ${BASE_TEXT_BODY_L}
  font-weight: 500;
`;

const BASE_TEXT_BODY_XL_MEDIUM = css`
  ${BASE_TEXT_BODY_XL}
  font-weight: 500;
`;

const BASE_TEXT_BODY_XXL_MEDIUM = css`
  ${BASE_TEXT_BODY_XXL}
  font-weight: 500;
`;

const BASE_TEXT_BODY_XS_BOLD = css`
  ${BASE_TEXT_BODY_XS}
  font-weight: 700;
`;

const BASE_TEXT_BODY_S_BOLD = css`
  ${BASE_TEXT_BODY_S}
  font-weight: 700;
`;

const BASE_TEXT_BODY_M_BOLD = css`
  ${BASE_TEXT_BODY_M}
  font-weight: 700;
`;

const BASE_TEXT_BODY_L_BOLD = css`
  ${BASE_TEXT_BODY_L}
  font-weight: 700;
`;

const BASE_TEXT_BODY_XL_BOLD = css`
  ${BASE_TEXT_BODY_XL}
  font-weight: 700;
`;

const BASE_TEXT_BODY_XXL_BOLD = css`
  ${BASE_TEXT_BODY_XXL}
  font-weight: 700;
`;

/**
 * Label text styles
 */

const BASE_TEXT_LABEL_XS = css`
  font-size: 0.625rem;
  line-height: 0.75rem;
`;

const BASE_TEXT_LABEL_S = css`
  font-size: 0.75rem;
  line-height: 1rem;
`;

const BASE_TEXT_LABEL_M = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const BASE_TEXT_LABEL_L = css`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const BASE_TEXT_LABEL_XS_MEDIUM = css`
  ${BASE_TEXT_LABEL_XS}
  font-weight: 500;
`;

const BASE_TEXT_LABEL_S_MEDIUM = css`
  ${BASE_TEXT_LABEL_S}
  font-weight: 500;
`;

const BASE_TEXT_LABEL_M_MEDIUM = css`
  ${BASE_TEXT_LABEL_M}
  font-weight: 500;
`;

const BASE_TEXT_LABEL_L_MEDIUM = css`
  ${BASE_TEXT_LABEL_L}
  font-weight: 500;
`;

const BASE_TEXT_LABEL_XS_BOLD = css`
  ${BASE_TEXT_LABEL_XS}
  font-weight: 700;
`;

const BASE_TEXT_LABEL_S_BOLD = css`
  ${BASE_TEXT_LABEL_S}
  font-weight: 700;
`;

const BASE_TEXT_LABEL_M_BOLD = css`
  ${BASE_TEXT_LABEL_M}
  font-weight: 700;
`;

const BASE_TEXT_LABEL_L_BOLD = css`
  ${BASE_TEXT_LABEL_L}
  font-weight: 700;
`;

/**
 * Title text styles
 */

const BASE_TEXT_TITLE_S = css`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
`;

const BASE_TEXT_TITLE_M = css`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const BASE_TEXT_TITLE_L = css`
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 600;
`;

/**
 * Headline text styles
 */

const BASE_HEADLINE_S = css`
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 500;
`;

const BASE_HEADLINE_M = css`
  font-size: 2.5rem;
  line-height: 2.75rem;
  font-weight: 500;
`;

const BASE_HEADLINE_L = css`
  font-size: 3rem;
  line-height: 3.25rem;
  font-weight: 500;
`;

/**
 * Display text styles
 */

const BASE_DISPLAY_S = css`
  font-size: 3.75rem;
  line-height: 4rem;
  font-weight: 600;
`;

const BASE_DISPLAY_M = css`
  font-size: 4rem;
  line-height: 4.25rem;
  font-weight: 600;
`;

const BASE_DISPLAY_L = css`
  font-size: 4.5rem;
  line-height: 4.75rem;
  font-weight: 600;
`;

/**
 * Admin Theme Typography
 */

export const ADMIN_TEXT_DISPLAY_S = adminText(BASE_DISPLAY_S);
export const ADMIN_TEXT_DISPLAY_M = adminText(BASE_DISPLAY_M);
export const ADMIN_TEXT_DISPLAY_L = adminText(BASE_DISPLAY_L);

export const ADMIN_TEXT_HEADLINE_S = adminText(BASE_HEADLINE_S);
export const ADMIN_TEXT_HEADLINE_M = adminText(BASE_HEADLINE_M);
export const ADMIN_TEXT_HEADLINE_L = adminText(BASE_HEADLINE_L);

export const ADMIN_TEXT_TITLE_S = adminText(BASE_TEXT_TITLE_S);
export const ADMIN_TEXT_TITLE_M = adminText(BASE_TEXT_TITLE_M);
export const ADMIN_TEXT_TITLE_L = adminText(BASE_TEXT_TITLE_L);

export const ADMIN_TEXT_LABEL_XS_MEDIUM = adminText(BASE_TEXT_LABEL_XS_MEDIUM);
export const ADMIN_TEXT_LABEL_S_MEDIUM = adminText(BASE_TEXT_LABEL_S_MEDIUM);
export const ADMIN_TEXT_LABEL_M_MEDIUM = adminText(BASE_TEXT_LABEL_M_MEDIUM);
export const ADMIN_TEXT_LABEL_L_MEDIUM = adminText(BASE_TEXT_LABEL_L_MEDIUM);

export const ADMIN_TEXT_LABEL_XS_BOLD = adminText(BASE_TEXT_LABEL_XS_BOLD);
export const ADMIN_TEXT_LABEL_S_BOLD = adminText(BASE_TEXT_LABEL_S_BOLD);
export const ADMIN_TEXT_LABEL_M_BOLD = adminText(BASE_TEXT_LABEL_M_BOLD);
export const ADMIN_TEXT_LABEL_L_BOLD = adminText(BASE_TEXT_LABEL_L_BOLD);

export const ADMIN_TEXT_BODY_XS_REGULAR = adminText(BASE_TEXT_BODY_XS_REGULAR);
export const ADMIN_TEXT_BODY_S_REGULAR = adminText(BASE_TEXT_BODY_S_REGULAR);
export const ADMIN_TEXT_BODY_M_REGULAR = adminText(BASE_TEXT_BODY_M_REGULAR);
export const ADMIN_TEXT_BODY_L_REGULAR = adminText(BASE_TEXT_BODY_L_REGULAR);
export const ADMIN_TEXT_BODY_XL_REGULAR = adminText(BASE_TEXT_BODY_XL_REGULAR);
export const ADMIN_TEXT_BODY_XXL_REGULAR = adminText(BASE_TEXT_BODY_XXL_REGULAR);

export const ADMIN_TEXT_BODY_XS_MEDIUM = adminText(BASE_TEXT_BODY_XS_MEDIUM);
export const ADMIN_TEXT_BODY_S_MEDIUM = adminText(BASE_TEXT_BODY_S_MEDIUM);
export const ADMIN_TEXT_BODY_M_MEDIUM = adminText(BASE_TEXT_BODY_M_MEDIUM);
export const ADMIN_TEXT_BODY_L_MEDIUM = adminText(BASE_TEXT_BODY_L_MEDIUM);
export const ADMIN_TEXT_BODY_XL_MEDIUM = adminText(BASE_TEXT_BODY_XL_MEDIUM);
export const ADMIN_TEXT_BODY_XXL_MEDIUM = adminText(BASE_TEXT_BODY_XXL_MEDIUM);

export const ADMIN_TEXT_BODY_XS_BOLD = adminText(BASE_TEXT_BODY_XS_BOLD);
export const ADMIN_TEXT_BODY_S_BOLD = adminText(BASE_TEXT_BODY_S_BOLD);
export const ADMIN_TEXT_BODY_M_BOLD = adminText(BASE_TEXT_BODY_M_BOLD);
export const ADMIN_TEXT_BODY_L_BOLD = adminText(BASE_TEXT_BODY_L_BOLD);
export const ADMIN_TEXT_BODY_XL_BOLD = adminText(BASE_TEXT_BODY_XL_BOLD);
export const ADMIN_TEXT_BODY_XXL_BOLD = adminText(BASE_TEXT_BODY_XXL_BOLD);

/**
 * Site Theme Typography
 */

export const TEXT_DISPLAY_S = siteHeadingText(BASE_DISPLAY_S);
export const TEXT_DISPLAY_M = siteHeadingText(BASE_DISPLAY_M);
export const TEXT_DISPLAY_L = siteHeadingText(BASE_DISPLAY_L);

export const TEXT_HEADLINE_S = siteHeadingText(BASE_HEADLINE_S);
export const TEXT_HEADLINE_M = siteHeadingText(BASE_HEADLINE_M);
export const TEXT_HEADLINE_L = siteHeadingText(BASE_HEADLINE_L);

export const TEXT_TITLE_S = siteHeadingText(BASE_TEXT_TITLE_S);
export const TEXT_TITLE_M = siteHeadingText(BASE_TEXT_TITLE_M);
export const TEXT_TITLE_L = siteHeadingText(BASE_TEXT_TITLE_L);

export const TEXT_LABEL_XS_MEDIUM = siteText(BASE_TEXT_LABEL_XS_MEDIUM);
export const TEXT_LABEL_S_MEDIUM = siteText(BASE_TEXT_LABEL_S_MEDIUM);
export const TEXT_LABEL_M_MEDIUM = siteText(BASE_TEXT_LABEL_M_MEDIUM);
export const TEXT_LABEL_L_MEDIUM = siteText(BASE_TEXT_LABEL_L_MEDIUM);

export const TEXT_LABEL_XS_BOLD = siteText(BASE_TEXT_LABEL_XS_BOLD);
export const TEXT_LABEL_S_BOLD = siteText(BASE_TEXT_LABEL_S_BOLD);
export const TEXT_LABEL_M_BOLD = siteText(BASE_TEXT_LABEL_M_BOLD);
export const TEXT_LABEL_L_BOLD = siteText(BASE_TEXT_LABEL_L_BOLD);

export const TEXT_BODY_XS_REGULAR = siteText(BASE_TEXT_BODY_XS_REGULAR);
export const TEXT_BODY_S_REGULAR = siteText(BASE_TEXT_BODY_S_REGULAR);
export const TEXT_BODY_M_REGULAR = siteText(BASE_TEXT_BODY_M_REGULAR);
export const TEXT_BODY_L_REGULAR = siteText(BASE_TEXT_BODY_L_REGULAR);
export const TEXT_BODY_XL_REGULAR = siteText(BASE_TEXT_BODY_XL_REGULAR);
export const TEXT_BODY_XXL_REGULAR = siteText(BASE_TEXT_BODY_XXL_REGULAR);

export const TEXT_BODY_XS_MEDIUM = siteText(BASE_TEXT_BODY_XS_MEDIUM);
export const TEXT_BODY_S_MEDIUM = siteText(BASE_TEXT_BODY_S_MEDIUM);
export const TEXT_BODY_M_MEDIUM = siteText(BASE_TEXT_BODY_M_MEDIUM);
export const TEXT_BODY_L_MEDIUM = siteText(BASE_TEXT_BODY_L_MEDIUM);
export const TEXT_BODY_XL_MEDIUM = siteText(BASE_TEXT_BODY_XL_MEDIUM);
export const TEXT_BODY_XXL_MEDIUM = siteText(BASE_TEXT_BODY_XXL_MEDIUM);

export const TEXT_BODY_XS_BOLD = siteText(BASE_TEXT_BODY_XS_BOLD);
export const TEXT_BODY_S_BOLD = siteText(BASE_TEXT_BODY_S_BOLD);
export const TEXT_BODY_M_BOLD = siteText(BASE_TEXT_BODY_M_BOLD);
export const TEXT_BODY_L_BOLD = siteText(BASE_TEXT_BODY_L_BOLD);
export const TEXT_BODY_XL_BOLD = siteText(BASE_TEXT_BODY_XL_BOLD);
export const TEXT_BODY_XXL_BOLD = siteText(BASE_TEXT_BODY_XXL_BOLD);
