import { ITheme } from 'models';
import { BORDER_RADIUS, TEXT_CAPITALIZATION, THEME_MODES, THEME_TYPES } from 'models/ITheme';
import { IAccountRole } from 'services/auth';
import { MAESTRO_WHITE } from 'style/constants';
import { DefaultTheme, IDefaultTheme } from 'styled-components';

export const TIME_NOW = 1525049053600;
export const USER_CREATED_TIME = TIME_NOW - (1000 * 60 * 60 * 6);
export const MOCK_SITE_ID = '5bfc67697e931b0060edd810';
export const MOCK_SITE_SLUG = 'fake-site-slug';
export const MOCK_ACCOUNT_ID = 'mock-accountId';
export const MOCK_ACCOUNT_EMAIL = 'mock-email@maestro.io';
export const MOCK_COLLECTION_ID = '426febd30000000000000000';
export const MOCK_OBJECT_ID = '5bfc67697e931b0060edd80b';
export const MOCK_THEME_ID = '5bfc67697e931b0060edd80c';

const mockEmailAccount = {
  _id: MOCK_ACCOUNT_ID,
  created: USER_CREATED_TIME,
  email: MOCK_ACCOUNT_EMAIL,
  exp: 1561937320,
  iat: 1559345320,
  lastLogin: 1553790925095,
  modified: 1554274800000,
  name: 'userUsername',
  roles: [
    {
      scope: '*',
      write: true,
      pageId: [] as string[],
    } as IAccountRole,
  ],
  service: 'email',
  siteId: '5c2d47ac0fc1f00069361a89',
  thirdPartyAuthProviders: [{
    accountId: '123123',
    provider: 'battlenet',
  }],
  uid: 'email-steve@maestro.io',
  tags: [],
};

const mockThirdPartyAccount = {
  ...mockEmailAccount,
  service: 'battlenet',
};

const mockTheme: ITheme = {
  _id: MOCK_THEME_ID,
  button: {
    borderRadius: BORDER_RADIUS.rounded,
    textCapitalization: TEXT_CAPITALIZATION.uppercase,
  },
  colors: {
    accentPrimary: '#6E5FFB',
    accentSecondary: '#4C42AD',
    accentTertiary: MAESTRO_WHITE,
    alertConfirmation: '#43D48F',
    alertWarning: '#F4D73D',
    alertError: '#FF0000',
    highlightPrimary: '#F3F7FF',
    highlightSecondary: '#9DA0A6',
    highlightTertiary: '#0C0D0D',
    surface1: '#080A0C',
    surface2: '#12141A',
    surface3: '#1B1E26',
    surface4: '#242833',
    surface5: '#363C4D',
    surface6: '#3E4659',
    text100: '#F3F7FF',
    text200: '#C2C5CC',
    text300: '#919499',
    text400: '#616266',
    text500: '#0C0D0D',
  },
  mode: THEME_MODES.DARK,
  name: 'mock-theme',
  siteId: MOCK_SITE_ID,
  type: THEME_TYPES.CLASSIC,
  typography: {
    body: 'san-serif',
    headline: 'sans-serif',
  },
};

const mockColorsAndFonts: IDefaultTheme = {
  button: {
    borderRadius: BORDER_RADIUS.rounded,
    textCapitalization: TEXT_CAPITALIZATION.uppercase,
  },
  colors: {
    accentPrimary: '#FF0000',
    accentSecondary: '#00FF00',
    accentTertiary: '#00FF00',
    alertConfirmation: '#5CBC1F',
    alertError: '#F0395C',
    alertWarning: '#F5A623',
    highlightPrimary: '#0000FF',
    highlightSecondary: '#FFFF00',
    highlightTertiary: '#FF00FF',
    surface1: '#FF0000',
    surface2: '#00FF00',
    surface3: '#0000FF',
    surface4: '#FFFF00',
    surface5: '#FF00FF',
    surface6: '#FF00FF',
    text100: '#000000',
    text200: '#111111',
    text300: '#222222',
    text400: '#333333',
    text500: '#444444',
    textContrastOnAccentPrimary: MAESTRO_WHITE,
    textContrastOnHighlightPrimary: '#00FFFF',
  },
  typography: {
    body: 'san-serif',
    headline: 'sans-serif',
  },
};

const mockThemeState: DefaultTheme = {
  admin: {
    ...mockColorsAndFonts,
    button: {
      borderRadius: BORDER_RADIUS.rounded,
      textCapitalization: TEXT_CAPITALIZATION.uppercase,
    },
  },
  site: {
    ...mockColorsAndFonts,
  },
};

export const generateMockEmailAccount = () => ({ ...mockEmailAccount });
export const generateRegularUserMockEmailAccount = () => ({ ...mockEmailAccount, roles: [] });
export const generateAdminMockEmailAccount = () => ({ ...mockEmailAccount, roles: [{ scope: '*', write: true, pageId: [] }] });
export const generateAuthTokenFromMock = (mockEmailAcc: any) => `test.${btoa(JSON.stringify({ ...mockEmailAcc }))}.test`;
export const generateAuthTokenEmail = (_id: string = MOCK_ACCOUNT_ID) => `test.${btoa(JSON.stringify({ ...mockEmailAccount, _id }))}.test`;
export const generateAuthTokenBattlenet = () => `test.${btoa(JSON.stringify(mockThirdPartyAccount))}.test`;
export const generateTheme = () => mockTheme;
export const generateThemeState = () => mockThemeState;

