export type TagNameTypeMap = {
  A: HTMLAnchorElement,
  DIV: HTMLDivElement,
};

export const isHtmlElement = <TTag extends keyof TagNameTypeMap>(
  tagName: TTag,
  el: Element,
): el is TagNameTypeMap[TTag] => (
  el?.tagName === tagName
);
