export const lazy = <T>(factory: () => T) => {
  let cached = false;
  let value: T;

  return () => {
    if (!cached) {
      cached = true;
      value = factory();
    }

    return value;
  };
};

export const lazyFn = <T extends (...args: any[]) => any>(
  factory: () => T,
) => {
  const wrapped = lazy(factory);
  return (
    (...args) => wrapped()(...args)
  ) as T;
};
