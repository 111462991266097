import { css, keyframes } from 'styled-components';
import {
  ADMIN_TEXT_100,
  ADMIN_ICON_SIZE,
  TAB_PADDING,
  ADMIN_ACCENT_PRIMARY,
  SPACING_MEDIUM,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_6,
  ADMIN_ACCENT_SECONDARY,
  ADMIN_ACCENT_TERTIARY,
  ADMIN_TEXT_200,
  ADMIN_TEXT_300,
  ADMIN_TEXT_400,
  ADMIN_TEXT_500,
  ADMIN_ALERT_ERROR,
  ADMIN_ALERT_WARNING,
  ADMIN_ALERT_CONFIRMATION,
  ADMIN_HIGHLIGHT_PRIMARY,
  ADMIN_HIGHLIGHT_SECONDARY,
  ADMIN_HIGHLIGHT_TERTIARY,
} from '../../constants';
import {
  SCROLL_BAR,
} from '../misc';

export const ADMIN_SCROLL_BAR = css`
  ${SCROLL_BAR}
  scrollbar-color: ${ADMIN_SURFACE_5} transparent;
  &::-webkit-scrollbar-thumb {
    background-color: ${ADMIN_SURFACE_5};
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb {
    background-color: ${ADMIN_SURFACE_5};
  }
  &::-webkit-scrollbar-track, & ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const ADMIN_PRIMARY_SCROLLBAR = css`
  ${ADMIN_SCROLL_BAR}
  &::-webkit-scrollbar-thumb {
    background-color: ${ADMIN_ACCENT_PRIMARY};
  }
  scrollbar-color: ${ADMIN_ACCENT_PRIMARY} transparent;
`;

export const ADMIN_THIN_SCROLL_BAR = css`
  ${ADMIN_SCROLL_BAR}
  &::-webkit-scrollbar {
    width: 3px;
  }
  & ::-webkit-scrollbar-track {
    width: 3px;
  }
`;

export const ADMIN_PANEL_EDITOR_BODY = css`
  padding: 0 ${SPACING_MEDIUM};
  padding-bottom: 35px;
`;

export const ADMIN_ICON_STANDARD = css`
  color: ${ADMIN_TEXT_100};
  font-size: ${ADMIN_ICON_SIZE};
  line-height: 50px;
`;

export const ADMIN_ICON_PASSIVE = css`
  ${ADMIN_ICON_STANDARD}
  opacity:0.7;
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FADE_IN = css`
  animation: 500ms ease-out ${fadeInAnimation};
`;

export const DEFAULT_FILL_WHITE = ({ color = 'white' }: { color?: string }) => color;

export const PANEL_SVG_SIZE: any = css`
  width: 18px;
  height: 18px;
`;

export const PANEL_V2_SVG_SIZE: any = css`
  width: 20px;
  height: 20px;
`;

export const PANEL_V2_ACTION_SVG_SIZE: any = css`
  width: 14px;
  height: 14px;
`;

export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ADMIN_BAR_TAB = css`
  padding: 0 ${TAB_PADDING} 50px ${TAB_PADDING};
`;

/**
 * Apply the class names to the svg to use the theme colors
 */
export const USE_ADMIN_THEME_COLORS_ON_SVG = css`
  & .fillSurface1 {
    fill: ${ADMIN_SURFACE_1};
  }
  & .strokeSurface1 {
    stroke: ${ADMIN_SURFACE_1};
  }
  & .fillSurface2 {
    fill: ${ADMIN_SURFACE_2};
  }
  & .strokeSurface2 {
    stroke: ${ADMIN_SURFACE_2};
  }
  & .fillSurface3 {
    fill: ${ADMIN_SURFACE_3};
  }
  & .strokeSurface3 {
    stroke: ${ADMIN_SURFACE_3};
  }
  & .fillSurface4 {
    fill: ${ADMIN_SURFACE_4};
  }
  & .strokeSurface4 {
    stroke: ${ADMIN_SURFACE_4};
  }
  & .fillSurface5 {
    fill: ${ADMIN_SURFACE_5};
  }
  & .strokeSurface5 {
    stroke: ${ADMIN_SURFACE_5};
  }
  & .fillSurface6 {
    fill: ${ADMIN_SURFACE_6};
  }
  & .strokeSurface6 {
    stroke: ${ADMIN_SURFACE_6};
  }



  & .fillAccentPrimary {
    fill: ${ADMIN_ACCENT_PRIMARY};
  }
  & .strokeAccentPrimary {
    stroke: ${ADMIN_ACCENT_PRIMARY};
  }
  & .fillAccentSecondary {
    fill: ${ADMIN_ACCENT_SECONDARY};
  }
  & .strokeAccentSecondary {
    stroke: ${ADMIN_ACCENT_SECONDARY};
  }
  & .fillAccentTertiary {
    fill: ${ADMIN_ACCENT_TERTIARY};
  }
  & .strokeAccentTertiary {
    stroke: ${ADMIN_ACCENT_TERTIARY};
  }

  & .fillHighlightPrimary {
    fill: ${ADMIN_HIGHLIGHT_PRIMARY};
  }
  & .strokeHighlightPrimary {
    stroke: ${ADMIN_HIGHLIGHT_PRIMARY};
  }
  & .fillHighlightSecondary {
    fill: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
  & .strokeHighlightSecondary {
    stroke: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
  & .fillHighlightTertiary {
    fill: ${ADMIN_HIGHLIGHT_TERTIARY};
  }
  & .strokeHighlightTertiary {
    stroke: ${ADMIN_HIGHLIGHT_TERTIARY};
  }


  & .fillText100 {
    fill: ${ADMIN_TEXT_100};
  }
  & .strokeText100 {
    stroke: ${ADMIN_TEXT_100};
  }
  & .fillText200 {
    fill: ${ADMIN_TEXT_200};
  }
  & .strokeText200 {
    stroke: ${ADMIN_TEXT_200};
  }
  & .fillText300 {
    fill: ${ADMIN_TEXT_300};
  }
  & .strokeText300 {
    stroke: ${ADMIN_TEXT_300};
  }
  & .fillText400 {
    fill: ${ADMIN_TEXT_400};
  }
  & .strokeText400 {
    stroke: ${ADMIN_TEXT_400};
  }
  & .fillText500 {
    fill: ${ADMIN_TEXT_500};
  }
  & .strokeText500 {
    stroke: ${ADMIN_TEXT_500};
  }



  & .fillAlertError {
    fill: ${ADMIN_ALERT_ERROR};
  }
  & .strokeAlertError {
    stroke: ${ADMIN_ALERT_ERROR};
  }
  & .fillAlertWarning {
    fill: ${ADMIN_ALERT_WARNING};
  }
  & .strokeAlertWarning {
    stroke: ${ADMIN_ALERT_WARNING};
  }
  & .fillAlertConfirmation {
    fill: ${ADMIN_ALERT_CONFIRMATION};
  }
  & .strokeAlertConfirmation {
    stroke: ${ADMIN_ALERT_CONFIRMATION};
  }
`;
