import { isHtmlElement, TagNameTypeMap } from './isHtmlElement';

export const findAncestor = <TTag extends keyof TagNameTypeMap>(
  tagName: TTag,
  element: Element,
): (TagNameTypeMap[TTag] | undefined | null) => {
  if (!element.parentElement)
    return;

  if (isHtmlElement(tagName, element.parentElement))
    return element.parentElement;

  return findAncestor(tagName, element.parentElement);
};
