export const makeFuture = <T>(): Future<T> => {
  let resolve = (_value: T) => {
    // placeholder
  };

  let reject = (_error: any) => {
    // placeholder
  };

  const promise = new Promise<T>((res, rej) => {
    resolve = (value) => res(value);
    reject = (error) => rej(error);
  });

  return {
    promise,
    resolve,
    reject,
  };
};

export type Future<T> = {
  promise: Promise<T>;
  reject: (error: any) => void;
  resolve: (value: T) => void;
};
