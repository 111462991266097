import { css, FlattenSimpleInterpolation, CSSObject } from 'styled-components';
import {
  DESKTOP_MIN_WIDTH_WITH_UNITS,
  MOBILE_LANDSCAPE_MEDIA,
  MOBILE_MAX_WIDTH_WITH_UNITS,
  MOBILE_PORTRAIT_MEDIA,
  DESKTOP_FHD_WIDTH_WITH_UNITS,
  DESKTOP_2K_WIDTH_WITH_UNITS,
  PORTRAIT_MEDIA,
} from '../constants';

const makeMediaQuery = (
  fn: <T extends any[] = any[]>(
    first: TemplateStringsArray | CSSObject,
    ...args: T
  ) => FlattenSimpleInterpolation | string,
) => fn;

/**
 * This should be called as a tagged template literal:
 * ```
 * mobileOnly`
 *   color: ${props => props.mobileColor};
 * `
 * ```
 */
export const mobileOnly = makeMediaQuery(
  (...args) => css`
    @media (max-width: ${MOBILE_MAX_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);

/**
 * This should be called as a tagged template literal:
 * ```
 * desktopOnly`
 *   color: ${props => props.desktopColor};
 * `
 * ```
 */
export const desktopOnly = makeMediaQuery(
  (...args) => css`
    @media (min-width: ${DESKTOP_MIN_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);

export const desktopFHDOnly = makeMediaQuery(
  (...args) => css`
    @media (min-width: ${DESKTOP_FHD_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);

export const desktop2KOnly = makeMediaQuery(
  (...args) => css`
    @media (min-width: ${DESKTOP_2K_WIDTH_WITH_UNITS}) {
      ${css(...args)}
    }
  `,
);

export const mobileLandscapeOnly = makeMediaQuery(
  (...args) => css`
    @media ${MOBILE_LANDSCAPE_MEDIA} {
      ${css(...args)}
    }
  `,
);

export const mobilePortraitOnly = makeMediaQuery(
  (...args) => css`
    @media ${MOBILE_PORTRAIT_MEDIA} {
      ${css(...args)}
    }
  `,
);

export const portraitOnly = makeMediaQuery(
  (...args) => css`
    @media ${PORTRAIT_MEDIA} {
      ${css(...args)}
    }
  `,
);

export const desktopHover = makeMediaQuery(
  (...args) => desktopOnly`
    &:hover {
      ${css(...args)}
    }
  `,
);
