import { bw } from 'colors';
import { css } from 'styled-components';
import {
  ACCENT_PRIMARY,
  ALERT_CONFIRMATION,
  ALERT_ERROR,
  ALERT_WARNING,
  HIGHLIGHT_PRIMARY,
  HIGHLIGHT_SECONDARY,
  HIGHLIGHT_TERTIARY,
  SURFACE_1,
  SURFACE_2,
  SURFACE_3,
  SURFACE_4,
  SURFACE_5,
  SURFACE_6,
  TEXT_100,
  TEXT_200,
  TEXT_300,
  TEXT_400,
  TEXT_500,
  TWITTER_BLUE,
} from '../constants';
import { BACKGROUND_IMAGE } from './backgrounds';
import { mobileOnly } from './mediaQueries';
import { ACCENT_SECONDARY } from '../constants';
import { ACCENT_TERTIARY } from '../constants';

export const SCROLL_BAR = css`
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: ${SURFACE_5} transparent;
  scrollbar-gutter: stable; // Make scrollbar to not take space of the content
  & ::-webkit-scrollbar-thumb {
    background: ${SURFACE_5};
    border-radius: 20px;
  }
  & ::-webkit-scrollbar-track {
    background: transparent;
    width: 5px;
  }
  & ::-webkit-scrollbar {
    width: 5px;
  }
`;

export const THIN_SCROLL_BAR = css`
  ${SCROLL_BAR}
  &::-webkit-scrollbar {
    width: 3px;
  }
`;

export const HIDE_SCROLLBAR = css`
  overflow: hidden auto;
  scrollbar-width: none;
  &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    width: 0;
    display: none;
  }
`;

export const INNER_BORDER = css<{ border?: string; borderPx?: number, borderRadius?: string }>`
  &:after {
    content: "";
    position: absolute;
    border: ${({ border = `1px solid ${ACCENT_PRIMARY}` }) => border};
    ${({ borderRadius }) => borderRadius && css`border-radius: ${borderRadius};`}
    ${({ borderPx = 1 }) => css`
      width: calc(100% - ${borderPx * 2}px);
      height: calc(100% - ${borderPx * 2}px);
    `}
    top: 0;
    left: 0;
  }
  position: relative;
`;

const TriangleDirectionMap = {
  top: (w: number, h: number, color: string) => css`
    border-width: 0 ${w}px ${h}px ${w}px;
    border-color: transparent transparent ${color} transparent;
  `,
  right: (w: number, h: number, color: string) => css`
    border-width: ${w}px 0 ${w}px ${h}px;
    border-color: transparent transparent transparent ${color};
  `,
  bottom: (w: number, h: number, color: string) => css`
    border-width: ${h}px ${w}px 0 ${w}px;
    border-color: ${color} transparent transparent transparent;
  `,
  left: (w: number, h: number, color: string) => css`
    border-width: ${w}px ${h}px ${w}px 0;
    border-color: transparent ${color} transparent transparent;
  `,
};

type DirectionKey = keyof typeof TriangleDirectionMap;

export const BOX_SHADOW_SMALL_SETTINGS = '0px 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.5)';

export const BOX_SHADOW_SMALL = `
  box-shadow: ${BOX_SHADOW_SMALL_SETTINGS};
`;

export const DIV_BUTTON = css`
  user-select: none;
  cursor: pointer;
`;

export const TWITTER_THEME = css`
  background-color: ${TWITTER_BLUE};
  color: ${bw(TWITTER_BLUE)};
`;

export const DISABLED = css<{ disabledOpacity?: number }>`
  user-select: none;
  pointer-events: none;
  cursor: default;
  opacity: ${({ disabledOpacity = 0.5 }) => disabledOpacity};
`;

export const VOLUME_ICON_STYLE = css<{ color?: string }>`
  line-height: 0;
  & > svg {
    width: 19px;
    height: 13px;
    & path {
      fill: ${({ color }) => color || ACCENT_PRIMARY};
    }
  }
`;

export const CIRCLE = css<{ radius?: string }>`
  border-radius: 100%;
  ${({ radius = '70px' }) => `
    width: ${radius};
    height: ${radius};
    min-width: ${radius};
    min-height: ${radius};
    max-width: ${radius};
    max-height: ${radius};
  `}
`;

export type TriangleProps = {
  triangleColor?: string;
  triangleDirection?: DirectionKey;
  triangleHeight?: number;
  triangleWidth?: number;
};

export const TRIANGLE = css<TriangleProps>`
  width: 0;
  height: 0;
  border-style: solid;
  ${({ triangleDirection = 'top', triangleColor, triangleWidth = 10, triangleHeight = 10, ...props }) => (
    TriangleDirectionMap[triangleDirection](
      triangleWidth / 2,
      triangleHeight,
      triangleColor || ACCENT_PRIMARY(props),
    )
  )}
`;

export const CIRCLE_IMAGE = css<{ radius?: string, src?: string | null }>`
  ${BACKGROUND_IMAGE}
  ${CIRCLE}
`;

export const FLEX_SEPARATION = css<{ childSeparation?: number, flexDirection?: 'row' | 'column' }>`
  ${({ childSeparation = 10, flexDirection = 'row' }) => {
    const direction = flexDirection === 'row' ? 'right' : 'top';
    return `
      display: flex;
      flex-flow: ${flexDirection} wrap;
      margin-${direction}: -${childSeparation}px;
      & > * {
        margin-${direction}: ${childSeparation}px;
        flex: 1 1 45%;
      }
    `;
  }}
`;

export const INNER_BACKGROUND = css<{ innerBackground?: string, innerBackgroundHeight?: string }>`
  &:after {
    content: "";
    ${({ innerBackground = 'transparent', innerBackgroundHeight = '100%' }) => css`
      background: ${innerBackground};
      height: ${innerBackgroundHeight};
    `}
    width: 100%;
    pointer-events: none;
    position: absolute;
    z-index: -1;
  }
`;

export const DISABLED_PROP = css<{ disabled?: boolean, disabledOpacity?: number }>`
  cursor: pointer;
  ${({ disabled }) => disabled && DISABLED}
`;

export const NO_HIGHLIGHT = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

// TODO: We'll grab the padding rules from the theme object once we have it
export const THEME_PADDING_DESKTOP_PX = 60;
const THEME_PADDING_MOBILE_PX = 16;
export const THEME_PADDING_DESKTOP = `${THEME_PADDING_DESKTOP_PX}px`;
export const THEME_PADDING_MOBILE = `${THEME_PADDING_MOBILE_PX}px`;

export const THEME_PADDING = css`
  padding: ${THEME_PADDING_DESKTOP};
  ${mobileOnly`
    padding: 40px;
    padding-inline: 16px;
  `}
`;

/**
 * Apply the class names to the svg to use the theme colors
 */
export const USE_SITE_THEME_COLORS_ON_SVG = css`
  & .fillSurface1 {
    fill: ${SURFACE_1};
  }
  & .strokeSurface1 {
    stroke: ${SURFACE_1};
  }
  & .fillSurface2 {
    fill: ${SURFACE_2};
  }
  & .strokeSurface2 {
    stroke: ${SURFACE_2};
  }
  & .fillSurface3 {
    fill: ${SURFACE_3};
  }
  & .strokeSurface3 {
    stroke: ${SURFACE_3};
  }
  & .fillSurface4 {
    fill: ${SURFACE_4};
  }
  & .strokeSurface4 {
    stroke: ${SURFACE_4};
  }
  & .fillSurface5 {
    fill: ${SURFACE_5};
  }
  & .strokeSurface5 {
    stroke: ${SURFACE_5};
  }
  & .fillSurface6 {
    fill: ${SURFACE_6};
  }
  & .strokeSurface6 {
    stroke: ${SURFACE_6};
  }



  & .fillAccentPrimary {
    fill: ${ACCENT_PRIMARY};
  }
  & .strokeAccentPrimary {
    stroke: ${ACCENT_PRIMARY};
  }
  & .fillAccentSecondary {
    fill: ${ACCENT_SECONDARY};
  }
  & .strokeAccentSecondary {
    stroke: ${ACCENT_SECONDARY};
  }
  & .fillAccentTertiary {
    fill: ${ACCENT_TERTIARY};
  }
  & .strokeAccentTertiary {
    stroke: ${ACCENT_TERTIARY};
  }

  & .fillHighlightPrimary {
    fill: ${HIGHLIGHT_PRIMARY};
  }
  & .strokeHighlightPrimary {
    stroke: ${HIGHLIGHT_PRIMARY};
  }
  & .fillHighlightSecondary {
    fill: ${HIGHLIGHT_SECONDARY};
  }
  & .strokeHighlightSecondary {
    stroke: ${HIGHLIGHT_SECONDARY};
  }
  & .fillHighlightTertiary {
    fill: ${HIGHLIGHT_TERTIARY};
  }
  & .strokeHighlightTertiary {
    stroke: ${HIGHLIGHT_TERTIARY};
  }

  & .fillText100 {
    fill: ${TEXT_100};
  }
  & .strokeText100 {
    stroke: ${TEXT_100};
  }
  & .fillText200 {
    fill: ${TEXT_200};
  }
  & .strokeText200 {
    stroke: ${TEXT_200};
  }
  & .fillText300 {
    fill: ${TEXT_300};
  }
  & .strokeText300 {
    stroke: ${TEXT_300};
  }
  & .fillText400 {
    fill: ${TEXT_400};
  }
  & .strokeText400 {
    stroke: ${TEXT_400};
  }
  & .fillText500 {
    fill: ${TEXT_500};
  }
  & .strokeText500 {
    stroke: ${TEXT_500};
  }



  & .fillAlertError {
    fill: ${ALERT_ERROR};
  }
  & .strokeAlertError {
    stroke: ${ALERT_ERROR};
  }
  & .fillAlertWarning {
    fill: ${ALERT_WARNING};
  }
  & .strokeAlertWarning {
    stroke: ${ALERT_WARNING};
  }
  & .fillAlertConfirmation {
    fill: ${ALERT_CONFIRMATION};
  }
  & .strokeAlertConfirmation {
    stroke: ${ALERT_CONFIRMATION};
  }
`;
