import React from 'react';
import Parser, { HTMLReactParserOptions } from 'html-react-parser';
import { camelCase, upperFirst } from 'lodash';
import invariant from 'invariant';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import ISubscription, { IPrice } from 'models/ISubscription';
import { ContentState, DraftInlineStyleType, convertToRaw } from 'draft-js';
import Color from 'color';
import draftToHtml from 'draftjs-to-html';
import { HTTPS_PREFIX } from 'hooks/use-link-input-validation';

export const hasOwn = (obj: object, key: string): boolean => obj && Object.prototype.hasOwnProperty.call(obj, key);

export const getOwn = <T extends Record<string, any>, U>(obj: T, key: string, fallback: U): T | U => (
  hasOwn(obj, key) ? obj[key] : fallback
);

export const sleepMs = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

export const sleepSeconds = (sec: number) => sleepMs(sec * 1000);

const parserOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    const name = (domNode.name || '').toLowerCase();
    if (name === 'script' || name === 'style') {
      return undefined;
    }
    return domNode;
  },
};

// TODO: Doesn't like allowtransparency iframe attr
export const parseInnerHTML =
  (innerHTML = '', options = parserOptions) => Parser(innerHTML, options);

export const getDocumentPath = (coll: string, id: string): string => {
  invariant(
    coll && typeof coll === 'string',
    `Invalid argument: Expected collection to be a non-empty string, but received ${coll}`,
  );

  invariant(
    id && typeof id === 'string',
    `Invalid argument: Expected id to be a non-empty string, but received ${
      JSON.stringify(id, null, 2)
    }`,
  );

  const path = `${coll}/${id}`;

  // TODO: Cache the result
  return path;
};

const secondsToUnits = (seconds: number): {
  days: number,
  hours: number;
  minutes: number;
  seconds: number;
} => (
  {
    days: Math.floor((seconds / 86400)),
    hours: Math.floor((seconds / 60 / 60) % 24),
    minutes: Math.floor((seconds / 60) % 60),
    seconds: Math.floor(seconds % 60),
  }
);

const getRestrictedStyle = (display: string, color?: string): string => {
  return `
    z-index: 100 !important;
    display: ${display} !important;
    position: inherit !important;
    visibility: visible !important;
    width: auto !important;
    max-width: inherit !important;
    min-width: auto !important;
    height: auto !important;
    max-height: inherit !important;
    min-height: auto !important;
    opacity: 1 !important;
    ${color ? `color: ${color} !important;` : ''}
  `;
};

export const forceVisibilityInDOM = (domNode: any, color: string): void => {
  if (domNode) {
    if(domNode.className.indexOf('MobileLinks__TermsOfUse') !== -1) {
      domNode.style = getRestrictedStyle('flex', color);
    } else if (domNode.tagName.toLowerCase() === 'span') {
      domNode.style = getRestrictedStyle('inline', color);
    } else if (domNode.getAttribute('data-info') === 'flex') {
      domNode.style = getRestrictedStyle('flex', color);
    } else {
      domNode.style = getRestrictedStyle('block', color);
    }

    domNode.setAttribute('hidden', 'false');

    const children = Array.prototype.slice.call(domNode?.children);

    if (children.length > 0) {
      children.forEach(childNode => forceVisibilityInDOM(childNode, color));
    }
  }
};

export const secondsToFormattedTime = (secs: number): string => {
  const { days, hours, minutes, seconds } = secondsToUnits(secs);
  const strHours = hours < 10 ? `0${hours}` : hours;
  const strMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const strSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const strDaysHours = days > 0 ? `${days}:${strHours}` : strHours;
  const out = strDaysHours === '00' ?
    `${strMinutes}:${strSeconds}` :
    `${strDaysHours}:${strMinutes}:${strSeconds}`;
  return out;
};

export const openPopup = (maxWidth: number, maxHeight: number, url: string, name: string): void => {
  // set popup window size
  const windowWidth = window.outerWidth;
  const windowHeight = window.outerHeight;
  const w = Math.min(maxWidth, windowWidth);
  const h = Math.min(maxHeight, windowHeight);
  const left = (windowWidth - w) / 2;
  const top = (windowHeight - h) / 2;

  window.open(url, name, [
    'toolbar=no',
    'location=no',
    'direreplaceWordsctories=no',
    'status=no',
    'menubar=no',
    'scrollbars=no',
    'resizable=no',
    'copyhistory=no',
    `width=${w}`,
    `height=${h}`,
    `top=${top}`,
    `left=${left}`,
  ].join(','));
};

const isAlphaRegex = /[a-zà-ú]/i;
const internalList = new Set(['fuck', 'nigger']);

export const replaceWords = (text: string, list: string[], replaceChar: string): string => {
  // Create a lower case version of the string so we can find indexes properly
  let lowerStr = text.toLowerCase();

  return list.reduce((accum, word) => {
    // Trim and lowercase the word so we have the same casing as the text
    const lowerWord = word.trim().toLowerCase();
    if (!lowerWord) { return accum; } // just in case

    let str = accum;
    let from = 0;

    do {
      // Get the index of the word in our lowercase string
      const index = lowerStr.indexOf(lowerWord, from);

      // If we can't find the word in our string, we are done.
      if (index === -1) {
        return str;
      }

      const afterEndIndex = index + lowerWord.length;
      const before = lowerStr[index - 1];
      const after = lowerStr[afterEndIndex];

      if ((index > 0 && isAlphaRegex.test(before)) ||
        (afterEndIndex < lowerStr.length && isAlphaRegex.test(after))) {
        if (!internalList.has(lowerWord)) {
          from = afterEndIndex;
          continue;
        }
      }

      const replace = replaceChar.repeat(lowerWord.length);

      // Recreate the lowercase string but with stars
      lowerStr = lowerStr.substr(0, index) + replace + lowerStr.substr(afterEndIndex);

      // Recreate the real string but with stars
      str = str.substr(0, index) + replace + str.substr(afterEndIndex);
    } while (true);
  }, text);
};

const hex = (value: number): string => Math.floor(value).toString(16);

export const createObjectId = () => hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));

// if this comment is still here do not merge!!!! this function might be broken!
// when keyfield is undefined on a collapsible list, the key updates and the inputs
// lose focus when a user types

export const getKey = <T>(keyField: string | ((value: T) => string), value: T): any => {
  let rawKey;
  if (!keyField) {
    // tslint:disable:next-line no-console
    console.warn(
      'Warning! You are calling getKey with a value that is falsey. Aborting to avoid terrible bugs.'
      ,
    );
    return;
  }
  if (typeof keyField === 'string') {
    rawKey = get(value, keyField);
  } else if (typeof keyField === 'function') {
    rawKey = keyField(value);
  } else {
    invariant(
      false,
      `computeEntries: keyField must be a string, func, or falsy value, but got type "${
        typeof keyField
      }": ${
        JSON.stringify(keyField, null, 2)
      }`,
    );
  }
  return rawKey;
};
/* eslint-enable */

export const stripEmptyObjectValues = (data: Record<string, any>): object => Object.keys(data).reduce((acc, key) => {
  const value = data[key];
  if (
    (typeof value === 'string' && value) ||
    (Array.isArray(value) && value.length > 0)
  ) {
    return {
      ...acc,
      [key]: data[key],
    };
  }
  return acc;
}, {});

export const isValidEmail = (email: string): boolean => {
  const match = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return match.test(email);
};
// This function removes illegal characters, joins to single string, replaces concurrent hyphens, removes hyphens at beginning or end, and lowercases.
export const createSlugFromString = (value: string): string => value.replace(/[^a-zA-Z0-9]/g, ' ').split(' ').join('-').replace(/-+/g, '-').replace(/(^-+)|(-+$)/g,'').toLowerCase();

export const toHHMMSS = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = secs % 60;

  return [hours,minutes,seconds]
    .filter((v, i) => v || i)
    .map(v => v.toString().padStart(2, '0'))
    .join(':');
};

export const getBrowserType = () => {
  const userAgent = window.navigator.userAgent;

  // Check for Edge
  const isUsingEdge = userAgent.indexOf('Edg') !== -1;
  if (isUsingEdge) {
    return 'edge';
  }

  // Note: Chrome's user agent also includes 'Safari', so we check for Chrome first
  const isUsingChrome = userAgent.indexOf('Chrome') !== -1;
  if (isUsingChrome) {
    return 'chrome';
  }

  // Note: Safari's user agent does not include 'Chrome'
  const isUsingSafari = userAgent.indexOf('Safari') !== -1 && !isUsingChrome;
  if (isUsingSafari) {
    return 'safari';
  }

  return '';
};


export const exponentialBackoffWaitMsg = 'Still working';
export const exponentialBackoffMessageWaitMs = 10000;
export const exponentialBackoffTotalWaitTimeMs = 89000;

export const hexToRgbaDecimal = (hexValue: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue);
  return result ? [
    parseInt(result[1], 16) / 255,
    parseInt(result[2], 16) / 255,
    parseInt(result[3], 16) / 255,
    1,
  ] : null;
};

export function getTabName(tab: string) {
  return `adminbar${upperFirst(camelCase(tab))}`;
}

export const toFixed = (value: number, precision: number): string => {
  const base = 10 ** precision;
  return (Math.round(value * base) / base).toFixed(precision);
};

export const hasAttributeSupport = (element: string, attribute: string, value: string) => {
  try {
    const input: any = document.createElement(element);
    input[attribute] = value;
    return input[attribute] === value;
  } catch(e) {
    return false;
  }
};

export const closestNumber = (list: number[], n: number, callback = (sorted: number[]) => sorted[0]) => (
  callback(list.sort((a, b) => Math.abs(n - a) - Math.abs(n - b)))
);

export const flattenCalls = <TA extends any[], TR>(fn: (...args: TA) => Promise<TR>): typeof fn => {
  let pending: { params: TA; promise: Promise<TR> }[] = [];
  return (...args: TA) => {
    const preExistingRequest = pending.find(({ params }) => isEqual(args, params));
    if (preExistingRequest) {
      return preExistingRequest.promise;
    } else {
      const promise = new Promise<TR>((resolve, reject) => {
        const clearPending = () => {
          pending = pending.filter(({ params }) => !isEqual(args, params));
        };
        fn(...args)
          .then((result) => {
            clearPending();
            resolve(result);
          })
          .catch((err) => {
            clearPending();
            reject(err);
          });
      });
      pending.push({
        params: args,
        promise,
      });
      return promise;
    }
  };
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isTouchDevice = !!window.matchMedia?.('(pointer: coarse)').matches;

export const supportsAspectRatioCSS = window.CSS?.supports('aspect-ratio', '16/9');

export const supportsMaskImageCSS =
  window.CSS?.supports('mask-image', 'linear-gradient(rgba(0,0,0), rgba(0,0,0,0))') ||
  window.CSS?.supports('-webkit-mask-image', 'linear-gradient(rgba(0,0,0), rgba(0,0,0,0))');

interface IsScrolledIntoViewOptions<T extends Element> {
  element: T;
  horizontal?: boolean;
  partial?: boolean;
  scrollContainer: T;
  scrollContainerOffset?: number;
  scrollContainerOffsetEnd?: number;
  scrollContainerOffsetStart?: number;
}

export const isScrolledIntoView = <T extends Element>({
  element,
  scrollContainer,
  scrollContainerOffset = 0,
  partial,
  horizontal,
  ...props
}: IsScrolledIntoViewOptions<T>) => {
  const {
    scrollContainerOffsetStart = scrollContainerOffset,
    scrollContainerOffsetEnd = scrollContainerOffset,
  } = props;

  const rect = element.getBoundingClientRect();
  const scrollRect = scrollContainer.getBoundingClientRect();

  const offsetStartKey = horizontal ? 'left' : 'right';
  const offsetEndKey = horizontal ? 'right' : 'left';

  const scrollOffsetStart = scrollRect[offsetStartKey];
  const scrollOffsetEnd = scrollRect[offsetEndKey];
  const offsetStart = rect[offsetStartKey] - scrollContainerOffsetStart;
  const offsetEnd = rect[offsetEndKey] + scrollContainerOffsetEnd;

  return partial ?
    offsetStart >= scrollOffsetStart && offsetEnd <= scrollOffsetEnd :
    offsetEnd >= scrollOffsetStart && offsetStart <= scrollOffsetEnd;
};

export const styleVisibleElements = <T extends Element>(
  options: Omit<IsScrolledIntoViewOptions<T>, 'element'>,
  callback: (style: CSSStyleDeclaration, isVisible: boolean) => void,
) => {
  const { children: elements } = options.scrollContainer;
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLElement;
    const isVisible = isScrolledIntoView({
      ...options,
      element: element as Element,
    });
    callback(element.style, isVisible);
  }
};
export const shadeHexColor = (color: string, percent: number) => {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent *- 1 : percent;
  /* tslint:disable:no-bitwise */
  const R = f >> 16;
  const G = f >> 8 & 0x00FF;
  const B = f & 0x0000FF;
  const hexValue = (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  /* tslint:enable:no-bitwise */
  return `#${hexValue}`;
} ;

export const lightOrDarkColor = (color: any) => {
  const { r, g, b } = getRGB(color);
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  );
  if (hsp>127.5) {
    return 'light';
  }
  return 'dark';
};

const getRGB = (color: any) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {
    color = +('0x' + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&',
    ));
    /* tslint:disable:no-bitwise */
    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
    /* tslint:enable:no-bitwise */
  }

  return { r, g, b };
};

/**
 * Output Color
 * Is a helper to use with shadeHexColor, determine the output color
 * @param {any} hex | hex color to be converted
 * @param {number} lum | luminosity percentage value
 * @returns {string} | returned hex color with luminosity applied
 */
export const outputColor = (color: string, perc: number) => {
  // If the color is light, we need to darken it
  if (lightOrDarkColor(color) === 'light') {
    perc = perc * -1;
  }
  return shadeHexColor(color, (perc / 100));
};

/**
 * isDraftJSRawData
 * It function helps us to check if an string is a raw element
 * generated for Rich Text Editor.
 */
export const isDraftJSRawData = (value: string): boolean => {
  try {
    const element = JSON.parse(value);
    if (element.hasOwnProperty('blocks')) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

// tslint:disable:no-bitwise
/**
 * Maps an object using the values in the fields array as keys
 * @param fields Array of fields to map the bit values to
 * @returns A map for easier access to the bit values
 */
export function createBitFieldMap<T extends string>(fields: readonly T[]) {
  const bitFields = {} as Record<T, number>;

  fields.forEach((field, i) => {
    bitFields[field] = 1 << i;
  });

  return bitFields;
}
/**
 * Creates a bitmask with the values in the bitFieldMap object
 * and uses the keys in that object to do bit operations and checks
 * @param bitFieldMap A map with the bit values, use createBitFieldMap to create it
 * @returns Utility functions to mutate the bitmask and check for included values
 */
export function createBitmask<T extends string>(bitFieldMap: Record<T, number>) {
  let bitmask = 0;

  return {
    add: (field: T) => bitmask |= bitFieldMap[field],
    has: (field: T) => !!(bitmask & bitFieldMap[field]),
    remove: (field: T) => bitmask &= ~bitFieldMap[field],
    reset: () => bitmask = 0,
  };
}
// tslint:enable:no-bitwise

export type WithExtraProps<T, ExtraProps> =
T extends React.ForwardRefExoticComponent<infer P1> ?
  React.ForwardRefExoticComponent<P1 & ExtraProps> :
  T extends React.Component<infer P2, infer S, infer SS> ?
    React.Component<P2 & ExtraProps, S, SS> :
  T extends React.FC<infer P3> ?
    React.FC<P3 & ExtraProps> :
    T extends React.ComponentType<infer P4> ?
      React.ComponentType<P4 & ExtraProps> :
      T extends (props: infer P5) => any ?
        (props: P5 & ExtraProps) => ReturnType<T> :
        T;

export type PromiseResult<T> = T extends Promise<infer K> ? K : T;
export type ResolvedReturnType<TF extends (...args: any) => any> = PromiseResult<ReturnType<TF>>;

export const getSubscriptionPrice = (
  subscription: ISubscription,
  nativeCurrency: string | null,
): IPrice => {
  if (!subscription || !subscription.prices || subscription?.prices?.length < 1) {
    return {
      value: subscription?.price || 0,
      currency: 'usd',
      default: true,
    };
  }
  let defaultPrice: IPrice = subscription.prices[0];
  for (const subPrice of subscription.prices) {
    if (subPrice.currency === nativeCurrency) {
      return subPrice;
    } else if (subPrice.default) {
      defaultPrice = subPrice;
    }
  }
  return defaultPrice;
};

interface IGetDefaultRichTextEditorDataParams {
  color?: string;
  fontSize?: number;
  text: string;
  typographicalEmphasis?: DraftInlineStyleType;
}

export const getDefaultRichTextEditorData = ({ text, fontSize, typographicalEmphasis, color }: IGetDefaultRichTextEditorDataParams) => {
  const raw = convertToRaw(ContentState.createFromText(text));
  if (fontSize !== undefined) {
    raw.blocks.forEach(block => {
      block.inlineStyleRanges = [...raw.blocks[0].inlineStyleRanges, {
        offset: 0,
        length: 1000,
        style: `fontsize-${fontSize}` as any,
      }];
    });
  }

  if (typographicalEmphasis) {
    raw.blocks.forEach(block => {
      block.inlineStyleRanges.push({
        offset: 0,
        length: 1000,
        style: typographicalEmphasis,
      });
    });
  }

  if (color) {
    raw.blocks.forEach(block => {
      block.inlineStyleRanges.push({
        offset: 0,
        length: 1000,
        style: `color-${Color(color).rgb().toString().replace(/\s/g, '')}` as any,
      });
    });
  }
  return draftToHtml(raw);
};

/**
 * Limits the maximum concurrent tasks/instances of `fn`.
 * Returns a function with the same signature as `fn` that can be called
 * as many times as you want just like you would call `fn`. It'll handle
 * batching for you
 */
export const batchedPromises = <TArgs extends any[], TResult>(
  fn: (...args: TArgs) => Promise<TResult>,
  batchSize: number,
) => {
  const pending: (() => Promise<unknown>)[] = [];
  let processingCount = 0;

  return (...args: TArgs) => new Promise<TResult>(
    (res, rej) => {
      if (processingCount >= batchSize) {
        pending.push(
          () => fn(...args).then(res).catch(rej),
        );
      } else {
        processingCount += 1;

        const handleNext = () => {
          const next = pending.shift();
          if (next) {
            next().finally(handleNext);
          } else {
            processingCount -= 1;
          }
        };

        fn(...args)
          .then(res)
          .catch(rej)
          .finally(handleNext);
      }
    },
  );
};

/**
 * Utility for reading properties from an `obj`
 * that can be either of type `A` or `B`.
 */
export const makePickFromUnion = <A, B>(
  isA: (obj: any) => boolean,
) => {
  return <
    AProp extends (keyof A) | undefined = undefined,
    BProp extends (keyof B) | undefined = undefined,
  >(
    obj: A | B,
    aProp?: AProp,
    bProp?: BProp,
  ): (
    AProp extends keyof A
      ? BProp extends keyof B
        ? A[AProp] | B[BProp]
        : A[AProp] | undefined
      : BProp extends keyof B
        ? B[BProp] | undefined
        : undefined
  ) => {
    if (isA(obj))
      // trust me, i'm an engineer
      return (obj as any)[aProp];

    return (obj as any)[bProp];
  };
};

/**
 * Utility Type to use const objects as enums
 */
export type ObjectValues<T> = T[keyof T];


/* tslint:disable:no-empty */
export const emptyFn = () => {};

const HELP_CENTER_URL_MAP = {
  'maestro': 'https://support.maestro.io/knowledge',
  'gettr': 'https://support.gettr.com/hc/en-us',
};

export const getHelpCenterURL = (app: string) => {
  return HELP_CENTER_URL_MAP[app] || 'https://support.maestro.io/knowledge';
};

export const onHitEnter = (callback: () => void) => (e: React.KeyboardEvent) => {
  if (e.key === 'Enter') {
    callback();
  }
};

export const generateRandomHexID = () => {
  return Array.from({ length: 24 }, () => Math.floor(Math.random() * 16).toString(16)).join('');
};

export const removeHttpsFromUrl = (url: string): string => {
  // matches with https:// where the s, :, /, / are optional, so any subset with those optional chars will be matched
  const HTTPS_REGAX_PARSER = /https?:?\/?\/?/g;
  let oldUrl= '';
  let newUrl = url;

  while (oldUrl !== newUrl) {
    oldUrl = newUrl;
    newUrl = newUrl.replace(HTTPS_REGAX_PARSER, '');
  }

  return oldUrl;
};

export const addHttpsToUrl = (url: string): string => url ? (`${HTTPS_PREFIX}${url}`) : url;
export const cleanAndFormatUrl = (url: string): string => (addHttpsToUrl(removeHttpsFromUrl(url)));
