import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { CANIPLAY_SERVICE_BASE_URL, EPG_SERVICE_BASE_URL, SERVICE_BASE_URL } from 'config';
import { persistenceService } from 'services/persistence';
import { getStoredAccessToken, parseDataFromAccessToken } from 'services/auth/api';
import { maestroAxiosInstance } from 'services/maestro-api-client';

const SRE_CONFIG_FILE_URL = 'https://storage.googleapis.com/trapdoor/trapdoor.json';
const SECOND_IN_MILLISECONDS = 1000;
const WHITE_LIST = [EPG_SERVICE_BASE_URL, CANIPLAY_SERVICE_BASE_URL];

interface IAxiosInstance {
    instance: AxiosInstance;
    slowInterceptor?: number;
    stopInterceptor?: number;
}

let AXIOS_INSTANCES: Array<IAxiosInstance> = [
    { instance: axios },
    { instance: maestroAxiosInstance },
];

const SRESetup = async () => {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }
    if (await isAdmin()) {
        return;
    }
    await initialSRECheck();
    startSRECheckInterval();
};

const generateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const startSRECheckInterval = () => {
    let cancelTokenSource: CancelTokenSource;

    const max = 60;
    const min = 40;
    const randomInterval = generateRandomNumber(min, max);

    const interval = setInterval(async () => {
        if (await isAdmin()) {
            checkRefresh(false);
            checkRequests('normal');
            clearInterval(interval);
            return;
        }

        const url = `${SRE_CONFIG_FILE_URL}?cacheBust=${Math.random()}`;
        if (cancelTokenSource) {
            cancelTokenSource.cancel();
        }
        cancelTokenSource = axios.CancelToken.source();

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: cancelTokenSource.token,
        }).then((response) => {
            const { data } = response;

            if (!data) {
                // tslint:disable-next-line:no-console
                console.error('Could not get data from trapdoor file');
                return;
            }

            const { refresh, siteId, requests, redirect } = data;

            if (siteId !== window.INIT.SITE._id) {
                return;
            }

            if (!redirect) {
                persistenceService().write('myTimeStamp', `${Date.now()}`, true);
            }

            checkRefresh(refresh);
            checkRequests(requests);
        }).catch((err) => {
            // tslint:disable-next-line:no-console
            console.error(`Could not get trapdoor file`, err);
        });
    }, SECOND_IN_MILLISECONDS * randomInterval);
};

const initialSRECheck = async () => {
    const url = `${SRE_CONFIG_FILE_URL}?cacheBust=${Math.random()}`;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const { data } = response;

        if (!data) {
            // tslint:disable-next-line:no-console
            console.error('Could not get data from trapdoor file');
            return;
        }

        const { redirect, redirectURL, redirectTreshold, timestamp, siteId, requests } = data;

        if (siteId !== window.INIT.SITE._id) {
            return;
        }

        checkRequests(requests);
        await checkRedirect(redirect, redirectURL, redirectTreshold, timestamp);

    } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(`Could not get trapdoor file`, err);
    }
};

const checkRefresh = (refresh: boolean) => {
    if (refresh) {
        // tslint:disable-next-line:no-console
        console.log('Refreshing...');
        window.location.reload();
    }
};

const checkRequests = (requests: string) => {
    switch (requests) {
        case 'slow':
            AXIOS_INSTANCES = AXIOS_INSTANCES.map(axiosInstance => {
                if (axiosInstance.stopInterceptor !== undefined) {
                    axiosInstance.instance.interceptors.request.eject(axiosInstance.stopInterceptor);
                    axiosInstance.stopInterceptor = undefined;
                }
                if (axiosInstance.slowInterceptor === undefined) {
                    axiosInstance.slowInterceptor = axiosInstance.instance.interceptors.request.use((request) => {
                        if (request.url?.includes(SERVICE_BASE_URL)) {
                            // tslint:disable-next-line:no-console
                            console.log(`Request to ${request.url} has been delayed`);
                            return new Promise(resolve => setTimeout(() => resolve(request), SECOND_IN_MILLISECONDS * generateRandomNumber(5, 10)));
                        }
                        return request;
                    });
                }
                return axiosInstance;
            });
            break;
        case 'stop':
            AXIOS_INSTANCES = AXIOS_INSTANCES.map(axiosInstance => {
                if (axiosInstance.slowInterceptor !== undefined) {
                    axiosInstance.instance.interceptors.request.eject(axiosInstance.slowInterceptor);
                    axiosInstance.slowInterceptor = undefined;
                }
                if (axiosInstance.stopInterceptor === undefined) {
                    axiosInstance.stopInterceptor = axiosInstance.instance.interceptors.request.use((request) => {
                        if (request.url?.includes(SERVICE_BASE_URL) && WHITE_LIST.every((url) => !request.url?.includes(url))) {
                            // tslint:disable-next-line:no-console
                            console.log(`Request to ${request.url} has been cancelled`);
                            return {
                                ...request,
                                cancelToken: new axios.CancelToken((cancel) => cancel(`Request to ${request.url} has been stopped`)),
                            };
                        }
                        return request;
                    });
                }
                return axiosInstance;
            });
            break;
        default:
            AXIOS_INSTANCES = AXIOS_INSTANCES.map(axiosInstance => {
                if (axiosInstance.slowInterceptor !== undefined) {
                    axiosInstance.instance.interceptors.request.eject(axiosInstance.slowInterceptor);
                    axiosInstance.slowInterceptor = undefined;
                }
                if (axiosInstance.stopInterceptor !== undefined) {
                    axiosInstance.instance.interceptors.request.eject(axiosInstance.stopInterceptor);
                    axiosInstance.stopInterceptor = undefined;
                }
                return axiosInstance;
            });
            break;
    }
};

const checkRedirect = async (redirect: boolean, redirectURL: string, redirectTreshold: number, timestamp: number) => {
    const persistence = persistenceService();

    if (!redirect) {
        persistence.write('myTimeStamp', `${Date.now()}`, true);
        return;
    }

    const rawTimeStamp = await persistence.read<string>('myTimeStamp', true);
    const myTimeStamp = rawTimeStamp ? parseInt(rawTimeStamp, 10) : Date.now();
    const timeOff = timestamp - myTimeStamp;
    const threshold = redirectTreshold * SECOND_IN_MILLISECONDS;
    if (myTimeStamp > timestamp || (timeOff > threshold)) {
        // tslint:disable-next-line:no-console
        console.log(`Redirecting to ${redirectURL}`);
        window.location.href = redirectURL;
    }
};

const isAdmin = async () => {
    const accessToken = await getStoredAccessToken();
    if (accessToken) {
        const userTokenData = parseDataFromAccessToken(accessToken);
        const adminRole = userTokenData.roles
            .find((role: any) => role.scope === '*' && role.write);
        return Boolean(adminRole);
    }
    return false;
};

export default SRESetup;
