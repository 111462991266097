import { ADMIN_TEXT_BODY_M_REGULAR } from 'style/design-system/textStyles';
import { css } from 'styled-components';

import {
  ADMIN_LINK_COLOR,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_LINK_COLOR_DISABLED,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
} from '../../constants';

const LINK_COLOR = css<{disabled?: boolean}>`
  color: ${(props) => props.disabled ? ADMIN_LINK_COLOR_DISABLED : ADMIN_LINK_COLOR};
`;
export const ADMIN_LINK = css<{disabled?: boolean}>`
  && {
    ${LINK_COLOR}
    pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
    ${ADMIN_TEXT_BODY_M_REGULAR}
    cursor: ${(props) => props.disabled ? 'auto' : 'pointer'};
    text-decoration: underline;
    &:link {
      ${LINK_COLOR}
    }
    &:visited {
      ${LINK_COLOR}
    }
    &:hover {
      color: ${ADMIN_TEXT_100};
    }

    &:active {
      color: ${ADMIN_ACCENT_PRIMARY};
    }
  }
`;

export const INPUT_LENGTH = css`
  position: absolute;
  align-self: center;
  right: 2em;
  color: ${ADMIN_TEXT_300};
`;
