import Linkify from 'react-linkify';
import styled, { css } from 'styled-components';

import {
  ACCENT_PRIMARY,
  BODY_FONT_FAMILY,
  PASSIVE_OPACITY,
  TITLE_FONT_FAMILY,
} from '../constants';
import { desktopOnly } from './mediaQueries';

type CssResponse = ReturnType<typeof css>;
export type textFn = (textStyle: CssResponse) => CssResponse;

export const CONTENT = css`
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
`;

export const CONTENT_FONT = css`
  font-family: ${BODY_FONT_FAMILY}, sans-serif;
`;

export const USER_CONTENT = css`
  ${CONTENT}
  ${CONTENT_FONT}
`;

const boldText: textFn = (textStyle) => css`
  ${textStyle}
  font-weight: bold;
`;

const titleText: textFn = (textStyle) => css`
  ${textStyle}
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: bold;
`;

const labelText: textFn = (textStyle) => css`
  ${USER_CONTENT}
  ${textStyle}
`;

export const TEXT_P1 = css`
  ${USER_CONTENT}
  font-size: 15px;
  line-height: 20px;
`;

export const TEXT_P2 = css`
  ${USER_CONTENT}
  font-size: 13px;
  line-height: 18px;
`;
export const TEXT_P2_BOLD = boldText(TEXT_P2);

export const TEXT_P3 = css`
  ${USER_CONTENT}
  font-size: 12px;
  line-height: 16.34px;
`;

export const TEXT_H1 = css`
  ${USER_CONTENT}
  font-size: 45px;
  line-height: 61px;
  letter-spacing: -0.03em;
`;
export const TEXT_H1_BOLD = boldText(TEXT_H1);

export const TEXT_H2 = css`
  ${USER_CONTENT}
  font-size: 30px;
  line-height: 41px;
`;
export const TEXT_H2_BOLD = boldText(TEXT_H2);

export const TEXT_H3 = css`
  ${USER_CONTENT}
  font-size: 25px;
  line-height: 34px;
`;
export const TEXT_H3_BOLD = boldText(TEXT_H3);

export const TEXT_H4 = css`
  ${USER_CONTENT}
  font-size: 19px;
  line-height: 26px;
`;
export const TEXT_H4_BOLD = boldText(TEXT_H4);
export const TEXT_H4_TITLE = titleText(TEXT_H4);

export const TEXT_H5 = css`
  ${USER_CONTENT}
  font-size: 14px;
  line-height: 19px;
`;
export const TEXT_H5_BOLD = boldText(TEXT_H5);
export const TEXT_H5_TITLE = titleText(TEXT_H5);
export const TEXT_H5_ALT = css`
  ${TEXT_P2}
  font-weight: 400;
`;

export const TEXT_H6 = css`
  ${USER_CONTENT}
  font-size: 12px;
  line-height: 16px;
`;
export const TEXT_H6_BOLD = boldText(TEXT_H6);
export const TEXT_H6_TITLE = titleText(TEXT_H6);

export const TEXT_H7 = css`
  ${USER_CONTENT}
  font-size: 9px;
  line-height: 12px;
`;
export const TEXT_H7_BOLD = boldText(TEXT_H6);

export const TEXT_LABEL1 = labelText(css`
  font-size: 20px;
  line-height: 27px;
`);

export const TEXT_LABEL2 = labelText(css`
  ${TEXT_H4}
`);

export const TEXT_LABEL3 = labelText(css`
  font-size: 16px;
  line-height: 22px;
`);

export const TEXT_LABEL4 = labelText(css`
  ${TEXT_H5}
`);

export const TEXT_LABEL5 = labelText(css`
  ${TEXT_H6}
`);

export const TEXT_LABEL6 = labelText(css`
  ${TEXT_P2}
`);

export const TEXT_LABEL7 = labelText(css`
  font-size: 11px;
  line-height: 15px;
`);

export const TEXT_BUTTON = css`
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const TEXT_BUTTON_ADMIN = css`
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`;

export const TEXT_TOOLTIP = css`
  ${USER_CONTENT}
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
`;

export const TEXT_LINK = css`
  ${TEXT_H6}
  text-decoration-line: underline;
`;

export const TEXT_BUTTON_NORMAL = css`
  ${TEXT_H6_TITLE}
  ${TEXT_BUTTON}
`;

export const TEXT_BUTTON_NORMAL_ADMIN = css`
  ${TEXT_H6_TITLE}
  ${TEXT_BUTTON_ADMIN}
`;

export const TEXT_BUTTON_LARGE_ADMIN = css`
  ${TEXT_H5_BOLD}
  ${TEXT_BUTTON_ADMIN}
`;

export const TEXT_BUTTON_LARGE = css`
  ${TEXT_H5_BOLD}
  ${TEXT_BUTTON}
`;

export const BODY_FONT = css`
  ${TEXT_H6}
  font-weight: 400;
`;

export const TITLE_FONT = css`
  font-family: ${TITLE_FONT_FAMILY}, sans-serif;
  font-size: 16px;
`;

export const ELLIPSIS = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BREAK_WORD = `
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const LINE_WRAP = `
  white-space: pre-line;
`;

export const LINK_MIXIN = css`
  & {
    text-decoration: none;
    cursor: pointer;
  }
  ${(props) => desktopOnly`
    &:link, &:active, &:hover, &:visited {
      color: ${ACCENT_PRIMARY(props)};
    }
  `}
`;

export const StyledLinkify = styled(Linkify).attrs({
  properties: {
    target: '_blank',
  },
})`
  ${BREAK_WORD}
  white-space: pre-line;
  & > a {
    ${LINK_MIXIN}
  }
`;

export const PASSIVE_TEXT = css`
  opacity: ${PASSIVE_OPACITY};
`;

export const TEXT_ELLIPSIS = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
`;

export const WORD_BREAK = css`
  -ms-word-break: break-all;
  -ms-word-wrap: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
`;

export const MULTI_LINE_ELLIPSIS = css<{maxLines?: number}>`
  ${WORD_BREAK}
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: ${({ maxLines = 2 }) => maxLines};
  -webkit-box-orient: vertical;

  display: -moz-inline-box;
  -moz-box-lines: ${({ maxLines = 2 }) => maxLines};
  -moz-box-orient: vertical;
`;

export const SINGLE_LINE_ELLIPSIS = css`
  ${TEXT_ELLIPSIS}
  ${WORD_BREAK}
`;
