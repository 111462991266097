import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';

export const transformMaestroHubUrl = (url: string) => (
  url ? `//${url.replace(/^(www\.)?maestro\.io/, 'sapphire.maestro.io')}` : ''
);

// Check to see if we are in an iframe.
export const isIFrame = () => window.self !== window.top;

// This will get the parent's URL if embedded
export const getTopLocationUrl = () => (
  // Because noreferrer can be specified, we will fall back to window.location.href.
  // Even if window.location.href is the wrong value, we don't want undefined via a blocked
  // document.referrer
  (isIFrame() && document.referrer) || window.location.href
);

// apparently this one is gud
export const isUrl = (url: string) => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);

// https://stackoverflow.com/questions/190852
export const getFileExtensionFromUrl = (value: string) => {
  const url = new URL(value).pathname;
  // tslint:disable:next-line no-bitwise
  return url.slice((url.lastIndexOf('.') - 1 >>> 0) + 2);
};

export const pickTextColorBasedOnBgColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return MAESTRO_BLACK;
  }

  // break the hex code into 3 pieces to get the individual red, green, and blue intensities
  const r = parseInt(hex.slice(0, 2), 16); // hexToR
  const g = parseInt(hex.slice(2, 4), 16); // hexToG
  const b = parseInt(hex.slice(4, 6), 16); // hexToB

  // The threshold of 186 is the line where we can see the light to darck color
  return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? MAESTRO_BLACK : MAESTRO_WHITE;
};

