import { ADMIN_TEXT_BODY_M_BOLD, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { css } from 'styled-components';
import {
  ACCENT_PRIMARY,
  ADMIN_ACTION_COLOR,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ACTION_COLOR_DISABLED,
  ADMIN_ACTION_COLOR_HOVER,
  SURFACE_1,
  SURFACE_2,
  SURFACE_3,
  SURFACE_4,
  SURFACE_5,
  ACCENT_SECONDARY,
  HIGHLIGHT_PRIMARY,
  HIGHLIGHT_TERTIARY,
  HIGHLIGHT_SECONDARY,
  SKIN_COLOR,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_HIGHLIGHT_PRIMARY,
  ADMIN_HIGHLIGHT_SECONDARY,
} from '../../constants';

const ACTION_BASE_STYLE = css`
  ${ADMIN_TEXT_BODY_M_BOLD}
  user-select: none;
  cursor: pointer;
`;

export const ACTION_ACTIVE = css`
  ${ACTION_BASE_STYLE}
  color: ${ADMIN_ACCENT_PRIMARY};
  &:hover{
    color: ${ADMIN_ACTION_COLOR_HOVER};
  }
`;

export const ACTION_DISABLED = css`
  ${ACTION_BASE_STYLE}
  color: ${ADMIN_ACTION_COLOR_DISABLED};
  cursor: auto;
  pointer-events: none;
`;

export const ACTION_NORMAL = css`
  ${ACTION_BASE_STYLE}
  color: ${ADMIN_ACTION_COLOR};
  pointer-events: auto;
  &:hover{
    color: ${ADMIN_ACTION_COLOR_HOVER};
  }
  &:active{
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const ADMIN_ACTION_NORMAL_SMALL = css`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  letter-spacing: 1px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  color: ${ADMIN_ACTION_COLOR};
  &:hover{
    color: ${ADMIN_ACTION_COLOR_HOVER};
    & svg > path {
      fill: ${ADMIN_ACTION_COLOR_HOVER};
    }
  }
  &:active{
    color: ${ADMIN_ACCENT_PRIMARY};
    & svg > path {
      fill: ${ADMIN_ACCENT_PRIMARY};
    }
  }
`;

export const ACTION_ICON_NORMAL = css`
  cursor: pointer;
  color: ${ADMIN_ACTION_COLOR};
  fill: ${ADMIN_ACTION_COLOR};
  &:hover{
    color: ${ADMIN_ACTION_COLOR_HOVER};
  }
  &:active{
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const ACTION_ICON_SVG = css`
  cursor: pointer;
  & svg {
    fill: ${ADMIN_ACTION_COLOR};
    &:hover{
      fill: ${ADMIN_ACTION_COLOR_HOVER};
    }
    &:active{
      fill: ${ADMIN_ACCENT_PRIMARY};
    }
  }
`;

export const NAV2_ICON_SVG: any = css`
  line-height: 0;
  & svg {
    width: 19px;
    height: 19px;
    & path {
      fill: ${(props: any) => props.color || ACCENT_PRIMARY(props)};
    }
  }
`;

export const THEME_ICON_SVG: any = css`
  & svg {
    & .surface-1 {
      fill: ${SURFACE_1};
    }
    & .surface-2 {
      fill: ${SURFACE_2};
    }
    & .surface-3 {
      fill: ${SURFACE_3};
    }
    & .surface-4 {
      fill: ${SURFACE_4};
    }
    & .surface-5 {
      fill: ${SURFACE_5};
    }
    & .accent-primary {
      fill: ${ACCENT_PRIMARY};
    }
    & .accent-secondary {
      fill: ${ACCENT_SECONDARY};
    }
    & .highlight-primary {
      fill: ${HIGHLIGHT_PRIMARY};
    }
    & .highlight-secondary {
      fill: ${HIGHLIGHT_SECONDARY};
    }
    & .highlight-tertiary {
      fill: ${HIGHLIGHT_TERTIARY};
    }
    & .skin-color {
      fill: ${SKIN_COLOR};
    }
  }
`;

export const CHANNEL_OR_PAGE_CREATION_ICON_SVG = css`
  & svg {
    & .admin-surface-1 {
      fill: ${ADMIN_SURFACE_1};
    }
    & .admin-surface-4 {
      fill: ${ADMIN_SURFACE_4};
    }
    & .admin-surface-5 {
      fill: ${ADMIN_SURFACE_5};
    }
    & .admin-highlight-primary {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
    & .admin-highlight-secondary {
      fill: ${ADMIN_HIGHLIGHT_SECONDARY};
    }
  }
`;
