import hash from 'json-stable-stringify';

export const makeCached = <TFn extends (...args: any[]) => Promise<any>>(
  fn: TFn,
  ttlMs: number | 'indefinitely' = 'indefinitely',
) => {
  const cache = new Map<string, ReturnType<TFn>>();
  const makeKey = (args: any) => hash(args);

  const clearCache = () => cache.clear();
  const invalidateKey = (key: string) => cache.delete(key);
  const invalidate = (...args: Parameters<TFn>) => invalidateKey(
    makeKey(args),
  );

  const cachedFn = (
    (...args) => new Promise(
      (res, rej) => {
        const key = makeKey(args);

        if (cache.has(key)) {
          res(cache.get(key));
          return;
        }

        fn(...args)
          .then((result) => {
            cache.set(key, result);

            if (ttlMs !== 'indefinitely') {
              setTimeout(
                () => invalidateKey(key),
                ttlMs,
              );
            }

            res(result);
          })
          .catch(rej);
      },
    )
  ) as TFn;

  return {
    cachedFn,
    clearCache,
    invalidate,
  } as const;
};
