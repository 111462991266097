import { css } from 'styled-components';
import {
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
} from 'style/constants';

export const ADMIN_BACKGROUND_CONTENT = css`
  background: ${ADMIN_SURFACE_4};
  color: ${ADMIN_TEXT_100};
`;

export const ADMIN_BACKGROUND_TITLE = css`
  background: ${ADMIN_SURFACE_3};
  color: ${ADMIN_TEXT_300};
`;

export const ADMIN_BACKGROUND_BLACK = css`
  background: ${ADMIN_SURFACE_1};
  color: ${ADMIN_TEXT_100};
  & svg, & svg > path {
    fill: ${ADMIN_TEXT_100};
  }
`;

export const ADMIN_BACKGROUND_DARK = css`
  background: ${ADMIN_SURFACE_4};
  color: ${ADMIN_TEXT_100};
  & svg, & svg > path {
    fill: ${ADMIN_TEXT_100};
  }
`;
