import { offset, bw, lighten, rgba } from 'colors';
import { css } from 'styled-components';

import {
  ACCENT_PRIMARY,
  SURFACE_1,
  SURFACE_4,
  SURFACE_3,
  SURFACE_2,
  SPACING_SMALL,
  TEXT_100,
  HIGHLIGHT_PRIMARY,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from '../constants';

export const BACKGROUND_DEFAULT = css`
  background-color: ${SURFACE_1};
  color: ${TEXT_100};
`;

export const BACKGROUND_DEFAULT_OFFSET = css`
  background-color: ${(props) => offset(SURFACE_1(props), 1)};
  color: ${TEXT_100};
`;

export const BACKGROUND_DEFAULT_LIGHTEN = css`
  background-color: ${(props) => lighten(SURFACE_1(props), 0.4)};
  color: ${TEXT_100};
`;

export const BACKGROUND_CONTENT = css`
  background-color: ${SURFACE_3};
  color: ${TEXT_100};
`;

export const BACKGROUND_CONTENT_OFFSET = css`
  background-color: ${(props) => offset(SURFACE_3(props), 1.5)};
  color: ${TEXT_100};
`;

export const BACKGROUND_CONTENT_BW = css`
  background-color: ${(props) => bw(SURFACE_3(props))};
  color: ${TEXT_100};
`;

export const BACKGROUND_CHANNEL = css`
  background-color: ${SURFACE_4};
  color: ${ACCENT_PRIMARY};
`;

export const BACKGROUND_ACTIVE = css`
  background-color: ${ACCENT_PRIMARY};
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
`;

export const PADDING_SMALL = css`
  padding: ${SPACING_SMALL};
`;

export const BACKGROUND_TITLE = css`
  background-color: ${SURFACE_2};
  color: ${HIGHLIGHT_PRIMARY};
`;

export const BACKGROUND_IMAGE = css<{src?: string | null}>`
  background: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BACKGROUND_ACTIVE_GRADIENT = css<{rotation?: number}>`
background: ${({ rotation = 0, ...props }) => css`
  linear-gradient(${rotation}deg, ${rgba(ACCENT_PRIMARY(props), 0.5)} -8.2%, ${rgba(SURFACE_4(props), 0)} 56.37%);
`}
`;
